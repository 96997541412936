<template>
  <div :class="boxName">
    <div class="box-title">个人中心</div>
    <div class="sec box-intro">
      <span class="img" :style="`background-image: url(${detail.img})`"></span>
      <div>{{detail.username}}</div>
      <a-select v-if="showMutiOpts" v-model="multi_company_id" style="min-width: 150px; margin-top: 0.5rem"
        @change="changeMultiStore"
        :options="parentOtps"/>
      
    </div>
    <div class="sec">
      <ul class="item-justify">
        <li @click="$router.push({name: 'voice', query: {state:detail.feature_status}})">
          <label>语音采集</label>
          <span :style="`color: ${detail.feature_status ? '#11C16F' : '#ED5A54'}`">{{detail.feature_status_name}}</span>
        </li>
      </ul>
    </div>
    <div class="sec">
      <ul class="item-justify">
        <li @click="$router.push({name: 'password'})">
          <label>修改密码</label>
          <span><i class="el-icon-arrow-right"></i></span>
        </li>
        <li>
          <label>版本信息</label>
          <span>{{detail.version}}</span>
        </li>
      </ul>
    </div>
      <a class="logout" @click="logout">退出登录</a>
    <NavbarDealer v-if="role.isDealer" tab="我的"></NavbarDealer>
    <NavbarAdmin v-else-if="role.isOwner" tab="我的"></NavbarAdmin>
    <AfNavBar v-else-if="role.isAfSeller" tab="我的"></AfNavBar>
    <AfNavbarDealer v-else-if="role.isAfDealer" tab="我的"></AfNavbarDealer>
    <AfNavbarDealer v-else-if="role.isAfDealerBoth" tab="我的" :both="true"></AfNavbarDealer>
    <AfNavbarOwner v-else-if="role.isAfOwner" tab="我的"></AfNavbarOwner>
    <AfNavbarOwner v-else-if="role.isAfOwnerBoth" tab="我的" :both="true"></AfNavbarOwner>
    <NavBar v-else tab="我的"></NavBar>
</div>    
</template>

<script>
import Vue from 'vue'
import AfNavBar from '@/components/afNavbar.vue';
import NavBar from '@/components/navbar.vue';
import NavbarAdmin from '@/components/navbarAdmin.vue';
import NavbarDealer from '@/components/navbarDealer.vue';
import AfNavbarDealer from '@/components/afNavbarDealer.vue';
import AfNavbarOwner from '@/components/afNavbarOwner.vue';
import { postForm } from '@/api'
import Cookies from 'js-cookie'
import {getRole} from '@/utils/getRole';

export default {
  data(){
    return {
      role: null,
      detail: {},
      parentOtps: [],
      multi_company_id: '',
      showMutiOpts: false,
      boxName: '',
    }
  },
  components:{
    AfNavBar,
    AfNavbarDealer,
    AfNavbarOwner,
    NavBar,
    NavbarDealer,
    NavbarAdmin,
  },
  methods: {
    changeMultiStore(v) {
      this.$store.commit("SET_MULTI_COM_QUERY", {company_id: v});
      Vue.ls.set('multi_company_id', v);
      postForm('/api/admin/setDefaultCompanyId', {company_id: v});
    },
    getMultiCompanyList() {
      postForm('api/option/getMultiCompanyList').then(res => {
        this.parentOtps = (res.data || []).map(o => ({
          value: o.id,
          label: String(o.code).toUpperCase() + o.name,
        }));
      })
    },
    
    logout() {
      this.$store.dispatch('Logout').then(() => {
        Vue.ls.remove('ACCESS_TOKEN')
        this.$router.push({name: 'login'});
      });
    },
  },
  created() {
    this.role = getRole();
    const {isSeller, isDealer, isOwner} = this.role;
    if (isSeller || isDealer || isOwner) {
      this.boxName = 'page-member bg-main';
    } else {
      this.boxName = 'page-member bg-main-af';
    }
    postForm('/h5/admin/my').then(res => {
        if (res.status === 1) {
          this.detail = res.data || {};
        } else {
          this.$message.error(res.msg);
        }
      });

    this.userInfo = Vue.ls.get('USER_INFO') || {};
    this.showMutiOpts = !!this.userInfo.is_multi_company;
    this.showMutiOpts && this.getMultiCompanyList();
    this.multi_company_id = Vue.ls.get('multi_company_id');
    
  }
  
}
</script>

<style lang="less">
.page-member {
  position: relative;

  .logout {
    position: fixed;
    left: 2rem;
    right: 2rem;
    bottom: 5rem;
    background: #fff;
    border-radius: 0.5rem;
    line-height: 2.3rem;
    color: #ED5A54;
    font-size: 0.8rem;
    text-align: center;
    max-width: 660px;
  }
  
  .box-title {
    color: #000;
    text-align: center;
    font-size: 0.8rem;
    padding: 1rem;
  }

  .box-intro {
    margin-top: 3rem;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 500;
    padding-bottom: 1.5rem;

    .img {
      display: inline-block;
      margin-top: -2.1rem;
      margin-bottom: 1rem;
      width: 4.3rem;
      height: 4.3rem;
      border-radius: 50%;
      background-color: #333;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
      border: 2px solid #FFFFFF;
    }
  }
}

</style>
